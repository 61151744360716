<template>
  <el-row>
      <fixed-item></fixed-item>
    <el-col class="mail-attachment" :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">邮件大附件助手</el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryOut">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
      </el-col>
      <el-col class="module doc-information">
        <el-col class="doc-interface">
          <img src="@/img/product/mail/img_mail_1.png">
        </el-col>
        <el-col class="doc-info">
          针对exchange邮箱以及Outlook客户端开发的邮件大附件解决方案，为企业提供高效、便捷、安全、经济、可控的大附件存储、传输与集中存储
        </el-col>
        <el-col class="doc-scene">
          <el-col class="title">
            六大应用场景
          </el-col>
          <el-col class="scene-list" :span="12">
            <el-col class="scene-item scene-top" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_1.png">
              </el-col>
              <el-col class="scene-title">
                发送端
              </el-col>
              <el-col class="scene-reason">
                邮件太大，发不出去
              </el-col>
            </el-col>
            <el-col class="scene-item scene-top" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_2.png">
              </el-col>
              <el-col class="scene-title">
                接收端
              </el-col>
              <el-col class="scene-reason">
                收件方邮件系统拒收大附件
              </el-col>
            </el-col>
            <el-col class="scene-item scene-top" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_3.png">
              </el-col>
              <el-col class="scene-title">
                系统兼容
              </el-col>
              <el-col class="scene-reason">
                更换现有邮件系统太过麻烦
              </el-col>
            </el-col>
            <el-col class="scene-item" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_4.png">
              </el-col>
              <el-col class="scene-title">
                经济成本
              </el-col>
              <el-col class="scene-reason">
                <el-col>邮箱容量增长快</el-col>
                <el-col>不易扩容；归档与备份成本高</el-col>
              </el-col>
            </el-col>
            <el-col class="scene-item" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_5.png">
              </el-col>
              <el-col class="scene-title">
                审计管控
              </el-col>
              <el-col class="scene-reason">
                <el-col>敏感数据,</el-col>
                <el-col>不易审计与管控</el-col>
              </el-col>
            </el-col>
            <el-col class="scene-item" :span="7">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_6.png">
              </el-col>
              <el-col class="scene-title">
                安全管理
              </el-col>
              <el-col class="scene-reason">
                <el-col>移动介质易传播病毒,</el-col>
                <el-col>外部网络工具和FTP工具不安全</el-col>
              </el-col>
            </el-col>
          </el-col>
        </el-col>
        <el-col class="doc-reason">
          <el-col class="title">您的企业为什么需要它</el-col>
          <el-col class="reason">针对Outlook客户端开发邮件大附件，为企业提供高效、便捷、安全、经济、可控的大附件存储与传输。</el-col>
<!--          <el-col class="trial-btn">-->
<!--            <el-col class="btn">在线试用<img src="../../img/common/right_arrow_white.svg"></el-col>-->
<!--          </el-col>-->



          <el-col class="reason-list" :span="16">

            <el-col class="reason-left"  :xs="1" :sm="4" :md="4" :lg="4" :xl="4" >
              <img src="../../img/product/mail/reason_1.png">
              <el-col class="content">
                无需更换邮件系统，无缝集成Exchang，支持Turbomail等企业级邮件
              </el-col>
            </el-col>

            <el-col class="reason-right" :xs="22" :sm="19" :md="19" :lg="19" :xl="18" >

              <el-col class="reason-right-item reason-item-1 item-top">
                <img src="../../img/product/mail/reason_2.png">
                <el-col class="content">
                  支持临时文件中转服务，解决邮件系统的快速扩容与归档需求
                </el-col>
              </el-col>

              <el-col class="reason-right-item reason-item-2 item-top">
                <img src="../../img/product/mail/reason_3.png">
                <el-col class="content">
                  提取码、有效期、下载次数，EV证书，确保分享文件安全
                </el-col>
              </el-col>

              <el-col class="reason-right-item reason-item-3 item-top">
                <img src="../../img/product/mail/reason_4.png">
                <el-col class="content">
                  日志审计与外发签核管控，有效的企业文档管控方式
                </el-col>
              </el-col>

              <el-col class="reason-right-item reason-item-4">
                <img src="../../img/product/mail/reason_5.png">
                <el-col class="content">
                  用户大邮件的接收与发送体验佳，提升工作效率
                </el-col>
              </el-col>

              <el-col class="reason-right-item reason-item-5">
                <img src="../../img/product/mail/reason_6.png">
                <el-col class="content">
                  AD域集成，单点登入，用户统一管理
                </el-col>
              </el-col>

              <el-col class="reason-right-item reason-item-6">
                <img src="../../img/product/mail/reason_7.png">
                <el-col class="content">
                  私有部署，数据自主可控
                </el-col>
              </el-col>

            </el-col>


          </el-col>


        </el-col>
      </el-col>
      <el-col class="module module-solve-problems">
        <el-col class="title">轻松解决企业邮件难题</el-col>
        <el-col class="content text-overflow-loc">
          以保障企业信息安全和改善用户体验为目标，面向企业邮件发件人、收件人、管理员提供丰富灵活的功能，同时通过后台任务计划实现自动化的管理
        </el-col>
        <el-col class="solve-list" :span="15">
          <el-col class="solve-item" :span="5">
            <el-col class="title">发件人</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list">
              <el-col>上传附件</el-col>
              <el-col>发送附件</el-col>
              <el-col>生成附件链接</el-col>
              <el-col>查看附件列表</el-col>
              <el-col>删除附件</el-col>
              <el-col>设置附件提取码</el-col>
              <el-col>设置下载次数</el-col>
              <el-col>设置过期日期</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="5">
            <el-col class="title">收件人</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list">
              <el-col>接受附件链接</el-col>
              <el-col>下载附件</el-col>
              <el-col>验证提取码</el-col>
              <el-col>验证下载次数</el-col>
              <el-col>验证过期日期</el-col>
              <el-col>数据分析与报表</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="5">
            <el-col class="title">管理员</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list">
              <el-col>阅后即焚</el-col>
              <el-col>管理用户</el-col>
              <el-col>统计用户配额</el-col>
              <el-col>管理组及配额</el-col>
              <el-col>管理角色及权限</el-col>
              <el-col>审计日志</el-col>
              <el-col>审批流程</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="5">
            <el-col class="title">任务计划</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list">
              <el-col>定时清理临时文件</el-col>
              <el-col>定时清理回收站</el-col>
              <el-col>定时同步AD用户</el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module module-income-value">
        <el-col class="title">客户收益与价值</el-col>
        <el-col class="income-list" :span="11">
          <el-col class="income-item item-top" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_1.svg">
            </el-col>
            <el-col class="small-title">
              低成本高收益
            </el-col>
            <el-col class="content">
              <el-col>预防企业邮箱容量增长过快，</el-col>
              <el-col>减低备份与归档成本</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item item-top" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_2.svg">
            </el-col>
            <el-col class="small-title">
              核心数据可控
            </el-col>
            <el-col class="content">
              <el-col>私有化部署，核心数据可控，</el-col>
              <el-col>外发签发管控，减少数据外泄风险</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item item-top" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_3.svg">
            </el-col>
            <el-col class="small-title">
              增强信息安全
            </el-col>
            <el-col class="content">
              <el-col>支持用户行为审计，外发签核管控，</el-col>
              <el-col>降低企业信息泄露的风险</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_4.svg">
            </el-col>
            <el-col class="small-title">
              数据集中管理
            </el-col>
            <el-col class="content">
              <el-col>轻松实现企业数据集中管理，</el-col>
              <el-col>提升工作效率</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_5.svg">
            </el-col>
            <el-col class="small-title">
              提升商务形象
            </el-col>
            <el-col class="content">
              <el-col>无需使用外部邮箱或网盘等，</el-col>
              <el-col>非商务方式传输文件</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="8">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_1.svg">
            </el-col>
            <el-col class="small-title">
              改善用户体验
            </el-col>
            <el-col class="content">
              <el-col>操作方便，用户使用熟悉的Outlook，</el-col>
              <el-col>轻松实现大附件的收发</el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-col>

    <el-col class="mail-attachment" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">邮件大附件助手</el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryOut">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
      </el-col>
      <el-col class="module doc-information">
        <el-col class="doc-interface">
          <img src="@/img/product/mail/img_mail_1.png">
        </el-col>
        <el-col class="doc-info">
          针对exchange邮箱以及Outlook客户端开发的邮件大附件解决方案，为企业提供高效、便捷、安全、经济、可控的大附件存储、传输与集中存储
        </el-col>
        <el-row class="doc-scene">
          <el-col class="title">
            六大应用场景
          </el-col>
<!--          <el-col class="scene-list" :span="24">-->
            <el-row class="scene-list">
              <el-col style="height: 1px;" :span="2"></el-col>
              <el-col class="scene-item scene-top" :span="10">
                <el-col class="img">
                  <img src="@/img/product/mail/mail_scene_1.png">
                </el-col>
                <el-col class="scene-title">
                  发送端
                </el-col>
                <el-col class="scene-reason">
                  邮件太大，发不出去
                </el-col>
              </el-col>
              <el-col class="scene-item scene-top" :span="10">
                <el-col class="img">
                  <img src="@/img/product/mail/mail_scene_2.png">
                </el-col>
                <el-col class="scene-title">
                  接收端
                </el-col>
                <el-col class="scene-reason">
                  收件方邮件系统拒收大附件
                </el-col>
              </el-col>
              <el-col style="height: 1px;" :span="2"></el-col>
            </el-row>



          <el-row class="scene-list" :span="12">
            <el-col style="height: 1px;" :span="2"></el-col>
            <el-col class="scene-item scene-top" :span="10">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_3.png">
              </el-col>
              <el-col class="scene-title">
                系统兼容
              </el-col>
              <el-col class="scene-reason">
                更换现有邮件系统太过麻烦
              </el-col>
            </el-col>
            <el-col class="scene-item" :span="10">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_4.png">
              </el-col>
              <el-col class="scene-title">
                经济成本
              </el-col>
              <el-col class="scene-reason">
                <el-col>邮箱容量增长快</el-col>
                <el-col>不易扩容；归档与备份成本高</el-col>
              </el-col>
            </el-col>
            <el-col style="height: 1px;" :span="2"></el-col>
          </el-row>

          <el-row class="scene-list" :span="12">
            <el-col style="height: 1px;" :span="2"></el-col>
            <el-col class="scene-item" :span="10">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_5.png">
              </el-col>
              <el-col class="scene-title">
                审计管控
              </el-col>
              <el-col class="scene-reason">
                <el-col>敏感数据,</el-col>
                <el-col>不易审计与管控</el-col>
              </el-col>
            </el-col>
            <el-col class="scene-item" :span="10">
              <el-col class="img">
                <img src="@/img/product/mail/mail_scene_6.png">
              </el-col>
              <el-col class="scene-title">
                安全管理
              </el-col>
              <el-col class="scene-reason">
                <el-col>移动介质易传播病毒,</el-col>
                <el-col>外部网络工具和FTP工具不安全</el-col>
              </el-col>
            </el-col>
            <el-col style="height: 1px;" :span="2"></el-col>
          </el-row>


<!--          </el-col>-->
        </el-row>
        <el-row class="doc-reason">
          <el-col class="title" style="color: #3764D7">您的企业为什么需要它</el-col>
          <el-col class="reason" style="color: #000000">针对Outlook客户端开发邮件大附件，为企业提供高效、便捷、安全、经济、可控的大附件存储与传输。</el-col>
<!--          <el-col class="trial-btn">-->
<!--            <el-col class="btn">在线试用<img src="../../img/common/right_arrow_white.svg"></el-col>-->
<!--          </el-col>-->

          <el-row>
            <el-col class="reason-list" :span="20">
              <el-row>
              <el-col class="reason-left"  :xs="24" :sm="4" :md="4" :lg="4" :xl="4" >
                <img src="../../img/product/mail/reason_1.png">
                <el-col class="content">
                  无需更换邮件系统，无缝集成Exchang，支持Turbomail等企业级邮件
                </el-col>
              </el-col>
              </el-row>
              <el-row class="reason-right" style="margin-top: 3vh">

                <el-col :span="12" class="reason-right-item reason-item-1 item-top">
                  <img src="../../img/product/mail/reason_2.png">
                  <el-col class="content">
                    支持临时文件中转服务，解决邮件系统的快速扩容与归档需求
                  </el-col>
                </el-col>

                <el-col :span="11" class="reason-right-item reason-item-2 item-top">
                  <img src="../../img/product/mail/reason_3.png">
                  <el-col class="content">
                    提取码、有效期、下载次数，EV证书，确保分享文件安全
                  </el-col>
                </el-col>

              </el-row>

              <el-row class="reason-right" style="margin-top: 3vh">
                <el-col :span="10" class="reason-right-item reason-item-3 item-top">
                  <img src="../../img/product/mail/reason_4.png">
                  <el-col class="content">
                    日志审计与外发签核管控，有效的企业文档管控方式
                  </el-col>
                </el-col>

                <el-col :span="13" class="reason-right-item reason-item-4">
                  <img src="../../img/product/mail/reason_5.png">
                  <el-col class="content">
                    用户大邮件的接收与发送体验佳，提升工作效率
                  </el-col>
                </el-col>
              </el-row>

              <el-row class="reason-right" style="margin-top: 3vh">
                <el-col :span="14" class="reason-right-item reason-item-5">
                  <img src="../../img/product/mail/reason_6.png">
                  <el-col class="content">
                    AD域集成，单点登入，用户统一管理
                  </el-col>
                </el-col>

                <el-col :span="9" class="reason-right-item reason-item-6">
                  <img src="../../img/product/mail/reason_7.png">
                  <el-col class="content">
                    私有部署，数据自主可控
                  </el-col>
                </el-col>
              </el-row>
<!--              <el-col class="reason-right" :xs="24" :sm="19" :md="19" :lg="19" :xl="18" >
              </el-col>-->


            </el-col>
          </el-row>
        </el-row>
      </el-col>
      <el-col class="module module-solve-problems">
        <el-col class="title">轻松解决企业邮件难题</el-col>
        <el-col class="content text-overflow-loc">
          以保障企业信息安全和改善用户体验为目标，面向企业邮件发件人、收件人、管理员提供丰富灵活的功能，同时通过后台任务计划实现自动化的管理
        </el-col>
        <el-col class="solve-list" :span="15">
          <el-col class="solve-item" :span="24" style="margin-bottom: 5vh" @click.native="toHidden">
            <el-col class="title">发件人</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list" v-if="itemListOne">
              <el-col>上传附件</el-col>
              <el-col>发送附件</el-col>
              <el-col>生成附件链接</el-col>
              <el-col>查看附件列表</el-col>
              <el-col>删除附件</el-col>
              <el-col>设置附件提取码</el-col>
              <el-col>设置下载次数</el-col>
              <el-col>设置过期日期</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="24" style="margin-bottom: 5vh" @click.native="toHiddenTwo">
            <el-col class="title">收件人</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list" v-if="itemListTwo">
              <el-col>接受附件链接</el-col>
              <el-col>下载附件</el-col>
              <el-col>验证提取码</el-col>
              <el-col>验证下载次数</el-col>
              <el-col>验证过期日期</el-col>
              <el-col>数据分析与报表</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="24" style="margin-bottom: 5vh" @click.native="toHiddenThree">
            <el-col class="title">管理员</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list" v-if="itemListThree">
              <el-col>阅后即焚</el-col>
              <el-col>管理用户</el-col>
              <el-col>统计用户配额</el-col>
              <el-col>管理组及配额</el-col>
              <el-col>管理角色及权限</el-col>
              <el-col>审计日志</el-col>
              <el-col>审批流程</el-col>
            </el-col>
          </el-col>
          <el-col class="solve-item" :span="24" style="margin-bottom: 5vh" @click.native="toHiddenFour">
            <el-col class="title">任务计划</el-col>
            <el-col class="line"></el-col>
            <el-col class="item-list" v-if="itemListFour">
              <el-col>定时清理临时文件</el-col>
              <el-col>定时清理回收站</el-col>
              <el-col>定时同步AD用户</el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module module-income-value">
        <el-col class="title">客户收益与价值</el-col>
        <el-col class="income-list" :span="24">
          <el-col class="income-item item-top" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_1.svg">
            </el-col>
            <el-col class="small-title">
              低成本高收益
            </el-col>
            <el-col class="content">
              <el-col>预防企业邮箱容量增长过快，
              减低备份与归档成本</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item item-top" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_2.svg">
            </el-col>
            <el-col class="small-title">
              核心数据可控
            </el-col>
            <el-col class="content">
              <el-col>私有化部署，核心数据可控，
              外发签发管控，减少数据外泄风险</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item item-top" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_3.svg">
            </el-col>
            <el-col class="small-title">
              增强信息安全
            </el-col>
            <el-col class="content">
              <el-col>支持用户行为审计，外发签核管控，
              降低企业信息泄露的风险</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_4.svg">
            </el-col>
            <el-col class="small-title">
              数据集中管理
            </el-col>
            <el-col class="content">
              <el-col>轻松实现企业数据集中管理，
              提升工作效率</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_5.svg">
            </el-col>
            <el-col class="small-title">
              提升商务形象
            </el-col>
            <el-col class="content">
              <el-col>无需使用外部邮箱或网盘等，
              非商务方式传输文件</el-col>
            </el-col>
          </el-col>
          <el-col class="income-item" :span="12">
            <el-col class="img">
              <img src="@/img/product/mail/icon/mail_icon_1.svg">
            </el-col>
            <el-col class="small-title">
              改善用户体验
            </el-col>
            <el-col class="content">
              <el-col>操作方便，用户使用熟悉的Outlook，
              轻松实现大附件的收发</el-col>
            </el-col>
          </el-col>

        </el-col>
      </el-col>
    </el-col>

    <el-col class="trial-back" style="position: relative;">
      <img src="../../img/product/cloudDoc/bg_2.png" style="height: 100%;width: 100%">
<!--      <el-col class="btn btn-position" @click.native="tryNow" style="">在线试用</el-col>-->
    </el-col>
  </el-row>
</template>

<script>
import FixedItem from "../../compoents/fixed/fixedItem";

export default {
    components: {FixedItem},
    name: "mailAttachment",
    data() {
      return {
        itemListOne:true,
        itemListTwo:true,
        itemListThree:true,
        itemListFour:true,
      }
    },
    methods: {
      tryOut() {
          this.$alert('正在开发中，敬请期待');
      },
      toHidden(){
        if(this.itemListOne) {
          this.itemListOne = false
        }else{
          this.itemListOne = true
        }
      },
      toHiddenTwo(){
        if(this.itemListTwo) {
          this.itemListTwo = false
        }else{
          this.itemListTwo = true
        }
      },
      toHiddenThree(){
        if(this.itemListThree) {
          this.itemListThree = false
        }else{
          this.itemListThree = true
        }
      },
      toHiddenFour(){
        if(this.itemListFour) {
          this.itemListFour = false
        }else{
          this.itemListFour = true
        }
      },

    }
}
</script>

<style lang="less" scoped>

@media screen and (max-width: 575px) {
  .text-overflow-loc{
    display: block;
    word-break: keep-all;
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .mail-attachment {
    background: #FFFFFF;
    margin-bottom: 120px;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }
    }
    .doc-information {
      background: url("../../img/product/cloudDoc/Group 1475@2x.png") no-repeat top center;
      width: 100%;
      height: auto;
      margin-top: 159px;

      .doc-interface {
        float: unset;
        margin: auto;
        position: relative;
        bottom: 111px;
        img{
          height: 100%;
          width: 100%;
        }
      }

      .doc-info {
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .doc-scene {
        .title {
          font-size: 40px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 56px;
          text-align: center;
          margin-bottom: 48px;
        }

        .scene-list {
          margin: auto;
          float: unset;

          .scene-item:nth-child(3n + 3) {
            margin-right: 0;
          }

          .scene-top {
            margin-bottom: 40px;
          }

          .scene-item {
            text-align: center;
            .scene-title {
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 24px;
              margin-top: 15px;
            }

            .scene-reason {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px;
              margin-top: 15px;
            }
            .img{
              img{
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
      .doc-reason {
        margin-top: 120px;
        .title {
          font-size: 2rem;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 56px;
          text-align: center;
        }
        .reason {
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
          margin-top: 24px;
        }

        .trial-btn {
          margin-bottom: 56px;
          .btn {
            border: 1px solid #FFFFFF;
            background-color: #3764D7;
            color: #FFFFFF;
          }
        }
        .reason-list {
          margin: auto;
          float: unset;
          .reason-left {
            margin-right: 1vw;
            /*background: url("../../img/product/mail/reason_1.png") no-repeat top center;*/
            height: 150px;
            text-align: left;
            position: relative;
            .content {
              font-size: 14px;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 24px;
              padding: 12vw 15vw;
              text-align: justify;
              position: absolute;
              top: 0px;
              left: 0px;
            }
            img{
              height: 100%;
              width: 100%;
            }
          }

          .reason-right {
            height: 201px;

            .item-top {
              margin-bottom: 13px;
            }

            .reason-right-item {
              height: 100%;
              .content {
                font-size: 14px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 24px;
              }
            }

            .reason-item-1 {
              /*background: url("../../img/product/mail/reason_2.png") no-repeat top center;*/
              margin-right: 3vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 17vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-2 {
              /*background: url("../../img/product/mail/reason_3.png") no-repeat top center;*/
              text-align: center;

              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                text-align: center;
                padding: 17vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-3 {
              /*background: url("../../img/product/mail/reason_4.png") no-repeat top center;*/
              margin-right: 3vw;
              text-align: center;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 17vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-4 {
              /*background: url("../../img/product/mail/reason_5.png") no-repeat top center;*/
              text-align: center;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 17vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-5 {
              /*background: url("../../img/product/mail/reason_6.png") no-repeat top center;*/
              text-align: center;
              margin-right: 3vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 17vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-6 {
              /*background: url("../../img/product/mail/reason_7.png") no-repeat top center;*/
              text-align: center;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 17vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
          }
        }

      }
    }

    .module-solve-problems {
      margin-top: 120px;
      .title {
        font-size: 2rem;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
      }
      .content {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        text-align: center;
        margin-top: 32px;
        margin-bottom: 48px;
      }
      .solve-list {
        margin: auto;
        float: unset;

        .solve-item:last-child {
          margin-right: 0;
        }

        .solve-item {
          background: #FFFFFF;
          box-shadow: 0px 8px 17px 0px rgba(171, 171, 171, 0.5);
          border-radius: 10px;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #3764D7;
            line-height: 24px;
            padding-top: 24px;
          }

          .line {
            width: 100%;
            height: 1px;
            border: 1px solid #E5E5E5;
            margin-top: 16px;
          }


          .item-list div:first-child {
            margin-top: 24px;
          }

          .item-list div {
            margin-top: 16px;
          }



          .item-list {

            div {
              font-size: 14px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
    }
    .module-income-value {
      margin-top: 120px;
      .title {
        font-size: 40px;
        font-weight: 500;
        color: #3764D7;
        line-height: 56px;
        text-align: center;
        margin-bottom: 48px;
      }
      .income-list {
        margin: auto;
        float: unset;

        .item-top {
          border-bottom: none !important;
        }

        .income-item:nth-child(3n + 3) {
          border-right: 1px solid #CCCCCC !important;
        }

        .income-item {
          border-right: none !important;

          .img {
            text-align: center;
            padding-top: 40px;
            img {
              border: 1px dashed #CCCCCC;
            }
          }
          .small-title {
            font-size: 18px;
            font-weight: 600;
            color: #3764D7;
            line-height: 24px;
            text-align: center;
            margin-top: 15px;
          }

          .content {
            font-size: 14px;
            font-weight: 400;
            color: #414141;
            line-height: 24px;
            text-align: center;
            margin-top: 15px;
          }
        }

        .income-item {
          height: 248px;
          border: 1px solid #CCCCCC;
        }
      }
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }

  .trial-back{
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }
    .btn-position{
      position: absolute;left: 28%;top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-back {
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;
      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}


@media screen and (min-width: 576px) {
  .text-overflow-loc{
    display: block;
    word-break: keep-all;
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .mail-attachment {
    background: #FFFFFF;
    margin-bottom: 120px;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }
    }
    .doc-information {
      background: url("../../img/product/cloudDoc/bg_1.png") no-repeat top center;
      width: 100%;
      height: auto;
      margin-top: 159px;

      .doc-interface {
        float: unset;
        margin: auto;
        width: 732px;
        position: relative;
        bottom: 111px;
      }

      .doc-info {
        width: 542px;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .doc-scene {
        .title {
          font-size: 40px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 56px;
          text-align: center;
          margin-bottom: 48px;
        }

        .scene-list {
          margin: auto;
          float: unset;

          .scene-item:nth-child(3n + 3) {
            margin-right: 0;
          }

          .scene-top {
            margin-bottom: 40px;
          }

          .scene-item {
            text-align: center;
            margin-right: 3vw;
            .scene-title {
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 24px;
              margin-top: 15px;
            }

            .scene-reason {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px;
              margin-top: 15px;
            }
          }
        }
      }
      .doc-reason {
        margin-top: 120px;
        .title {
          font-size: 40px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 56px;
          text-align: center;
        }
        .reason {
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
          margin-top: 24px;
        }

        .trial-btn {
          margin-bottom: 56px;
          .btn {
            border: 1px solid #FFFFFF;
            background-color: #3764D7;
            color: #FFFFFF;
          }
        }
        .reason-list {
          margin: auto;
          float: unset;
          .reason-left {
            margin-right: 1vw;
            /*background: url("../../img/product/mail/reason_1.png") no-repeat top center;*/
            height: 417px;
            text-align: left;
            position: relative;
            .content {
              font-size: 14px;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 24px;
              padding: 9vw 2vw;
              text-align: justify;
              position: absolute;
              top: 0px;
              left: 0px;
            }
            img{
              height: 100%;
              width: 100%;
            }
          }

          .reason-right {
            height: 201px;

            .item-top {
              margin-bottom: 13px;
            }

            .reason-right-item {
              height: 100%;
              .content {
                font-size: 14px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 24px;
              }
            }

            .reason-item-1 {
              /*background: url("../../img/product/mail/reason_2.png") no-repeat top center;*/
              width: 12vw;
              margin-right: 1vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 3.5vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-2 {
              /*background: url("../../img/product/mail/reason_3.png") no-repeat top center;*/
              width: 19vw;
              text-align: center;
              margin-right: 1vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                text-align: center;
                padding: 4vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-3 {
              /*background: url("../../img/product/mail/reason_4.png") no-repeat top center;*/
              width: 15vw;
              text-align: center;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 4vw 2vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-4 {
              /*background: url("../../img/product/mail/reason_5.png") no-repeat top center;*/
              width: 19vw;
              text-align: center;
              margin-right: 1vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 4vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-5 {
              /*background: url("../../img/product/mail/reason_6.png") no-repeat top center;*/
              width: 15vw;
              text-align: center;
              margin-right: 1vw;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 4vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
            .reason-item-6 {
              /*background: url("../../img/product/mail/reason_7.png") no-repeat top center;*/
              width: 12vw;
              text-align: center;
              position: relative;
              .content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 4vw 3vw;
              }
              img{
                height: 100%;
                width: 100%;
              }
            }
          }
        }

      }
    }

    .module-solve-problems {
      margin-top: 120px;
      .title {
        font-size: 40px;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
      }
      .content {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
        text-align: center;
        padding: 0 30vw;
        margin-top: 32px;
        margin-bottom: 48px;
      }
      .solve-list {
        margin: auto;
        float: unset;

        .solve-item:last-child {
          margin-right: 0;
        }

        .solve-item {
          height: 416px;
          background: #FFFFFF;
          box-shadow: 0px 8px 17px 0px rgba(171, 171, 171, 0.5);
          border-radius: 10px;
          margin-right: 2vw;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #3764D7;
            line-height: 24px;
            padding-top: 24px;
          }

          .line {
            width: 216px;
            height: 1px;
            border: 1px solid #E5E5E5;
            margin-left: 16px;
            margin-top: 16px;
          }


          .item-list div:first-child {
            margin-top: 24px;
          }

          .item-list div {
            margin-top: 16px;
          }



          .item-list {

            div {
              font-size: 14px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
    }
    .module-income-value {
      margin-top: 120px;
      .title {
        font-size: 40px;
        font-weight: 500;
        color: #3764D7;
        line-height: 56px;
        text-align: center;
        margin-bottom: 48px;
      }
      .income-list {
        margin: auto;
        float: unset;

        .item-top {
          border-bottom: none !important;
        }

        .income-item:nth-child(3n + 3) {
          border-right: 1px solid #CCCCCC !important;
        }

        .income-item {
          border-right: none !important;

          .img {
            text-align: center;
            padding-top: 40px;
            img {
              border: 1px dashed #CCCCCC;
            }
          }
          .small-title {
            font-size: 18px;
            font-weight: 600;
            color: #3764D7;
            line-height: 24px;
            text-align: center;
            margin-top: 15px;
          }

          .content {
            font-size: 14px;
            font-weight: 400;
            color: #414141;
            line-height: 24px;
            text-align: center;
            margin-top: 15px;
          }
        }

        .income-item {
          height: 248px;
          border: 1px solid #CCCCCC;
        }
      }
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }

  .trial-back{
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }
    .btn-position{
      position: absolute;left: 44%;top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-back {
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;
      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}
</style>
